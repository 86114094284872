.wrapper {
  display: flex;
}

.stars {
  display: flex;
}

.reviews_count {
  margin-left: 5px;
  line-height: 1.5;
  color: #000;
  text-decoration: none;
  display: flex;
  align-self: center;

  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 575px) {
  .wrapper {
    flex-direction: row;
  }

  .reviews_count {
    margin-top: 0;
    margin-left: 10px;
  }
}
